import React, { useEffect } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import ChatBox from "../../../shared/chat-box";
import { useAppSelector } from "../../../../store";
import { useNavigate } from "react-router-dom";
import "./account.scss";

const predefinedAnswers: string[] = [];

const Account: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentStep = useAppSelector((state) => state?.steps.connection.currentStep);

  const handleMessageChange = () => {
    //
  };

  const handleSendMessage = () => {
    //
  };

  useEffect(() => {
    if (currentStep !== 2) {
      navigate("/connection/review");
    }
  }, []);

  return (
    <Grid container item spacing={2} sx={{ p: 3 }} overflow="auto" height="100%">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={[
            {
              isAnswer: true,
              text:
                "Thank for selecting this expert. Before we can confirm the date and time, as this is your first time booking on our platform, please follow the instructions on the right hand side to create your own account or by logging into your preferred social media account.",
            },
          ]}
          message={""}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={false}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "hidden",
              padding: "16px",
            }}
          >
            <img src="/images/register.png" alt="Calendar" style={{ height: "100%", width: "100%" }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Account;
