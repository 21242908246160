import React, { useState } from "react";
import { Container, Paper } from "@mui/material";
import Stepper from "../../shared/stepper";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { nextStep, previousStep, setExpertAccountView, setPermitted } from "../../../store/steps";
import { useAppDispatch, useAppSelector } from "../../../store";
import { chatRequest, ChatRequest, resetMessages } from "../../../store/chat";
import { useSnackbar } from "../../../hooks/useSnackbar";
import "./expert-steps.scss";

const getPathStepIndex = (path: string) => {
  switch (path) {
    case "/expert/upload":
      return 0;
    case "/expert/refine":
      return 1;
    case "/expert/account":
      return 2;
    case "/expert/share":
      return 3;
    default:
      return -1;
  }
};

const buttonsConfig = {
  back: [false, false, false, false],
  next: [false, false, false, false],
  confirm: [false, true, true, false],
  share: [false, false, false, true],
};

const ExpertSteps: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentStep, steps, permitted } = useAppSelector((state) => state.steps.expert);
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbarMessage } = useSnackbar();
  const currentPath = location.pathname;
  const stepIndex = getPathStepIndex(currentPath);
  const uuid = useAppSelector((state) => state.auth.uuid);
  const chat = useAppSelector((state) => state.chat);
  const currentView = useAppSelector((state) => state.steps.expert.view.account);
  const [loading, setLoading] = useState<boolean>(false);

  const confirmStep = async () => {
    if (uuid) {
      const body: ChatRequest = { prompt_type: currentStep === 1 ? "bio" : "rates", confirm: true };
      const response = await dispatch(chatRequest({ uuid, body }));

      if (chatRequest.fulfilled.match(response)) {
        dispatch(resetMessages());
        dispatch(setPermitted({ step: currentStep + 1, permitted: true, type: "expert" }));
        dispatch(nextStep({ type: "expert" }));
        const nextPath =
          stepIndex + 1 < steps.length ? `/expert/${steps[stepIndex + 1].toLowerCase()}` : "/expert/upload";
        navigate(nextPath);
      } else if (chatRequest.rejected.match(response)) {
        showSnackbarMessage(`Failed to confirm step: ${chat.error}`, "error");
      } else {
        showSnackbarMessage(`Failed to confirm step`, "error");
      }
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (stepIndex === 1) {
      await confirmStep();
    } else {
      switch (currentView) {
        case "rates":
          dispatch(setExpertAccountView("calendar"));
          break;
        case "calendar":
          dispatch(setExpertAccountView("payment"));
          break;
        case "payment":
          dispatch(setExpertAccountView(null));
          await confirmStep();
          break;
        default:
          break;
      }
    }

    setLoading(false);
  };

  const handleShare = async () => {
    navigate("/expert/congratulations");
  };

  const handleNext = () => {
    const nextPath = stepIndex + 1 < steps.length ? `/expert/${steps[stepIndex + 1].toLowerCase()}` : "/expert/upload";
    navigate(nextPath);
    dispatch(nextStep({ type: "expert" }));
  };

  const handleBack = () => {
    const prevPath = stepIndex - 1 >= 0 ? `/expert/${steps[stepIndex - 1].toLowerCase()}` : "/expert/upload";
    navigate(prevPath);
    dispatch(previousStep({ type: "expert" }));
  };

  return (
    <Container sx={{ minHeight: "100%" }}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          height: "calc(100% - 50px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          maxHeight: "100vh",
        }}
      >
        <Stepper
          activeStep={currentStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          handleConfirm={handleConfirm}
          handleShare={handleShare}
          content={<Outlet />}
          permitted={permitted}
          loading={loading}
          buttonsConfig={buttonsConfig}
        />
      </Paper>
    </Container>
  );
};

export default ExpertSteps;
