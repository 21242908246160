import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "./auth";
import uploadReducer from "./upload";
import stepsReducer from "./steps";
import snackbarReducer from "./snackbar";
import chatReducer from "./chat";
import shareReducer from "./share";
import servicesReducer from "./service";
import profileReviewReducer from "./profile-review";
import profileReducer from "./profile";

const rootReducer = combineReducers({
  auth: authReducer,
  upload: uploadReducer,
  steps: stepsReducer,
  snackbar: snackbarReducer,
  chat: chatReducer,
  share: shareReducer,
  service: servicesReducer,
  profileReview: profileReviewReducer,
  profile: profileReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
