import React from "react";
import { Box, Typography, Grid, Paper, useTheme } from "@mui/material";
import { Service } from "../../../models";
import "./profile-rates.scss";

interface ProfileRatesProps {
  services: Array<Service>;
}

const ProfileRates: React.FC<ProfileRatesProps> = ({ services }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: "8px",
        overflow: "auto",
      }}
    >
      <Grid container spacing={2} sx={{ p: 2, overflow: "auto" }}>
        <Grid item xs={12} md={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginBottom: 2 }} color={theme.palette.primary.main}>
            Services and Rates
          </Typography>
        </Grid>

        {services.map((service, index) => (
          <Grid item xs={12} md={12} key={index}>
            <Box sx={{ marginBottom: 4 }}>
              <Typography variant="subtitle2" color={theme.palette.primary.main} fontWeight="bold">
                {service.category}
              </Typography>
              {service.items.map((item, idx) => (
                <Paper elevation={1} sx={{ p: 2, mb: 1 }} key={idx}>
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="body2" fontWeight="bold">{item.name}:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" align="right">
                        {item.rate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfileRates;
