import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import StarIcon from "@mui/icons-material/Star";
import LoginIcon from "@mui/icons-material/Login";
import { Box, Button, Grid, Link, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { logout } from "../../../store/auth";
import { useLocation } from "react-router-dom";
import "./header.scss";

const Header: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("uuid");
  };

  const handleClickExpertButton = () => {
    navigate("/expert");
  };

  const getHeaderText = () => {
    if (location.pathname.startsWith("/expert")) {
      return "You're an expert to someone";
    }
    return "Leverage experience when you need it";
  };

  return (
    <Grid container display="flex" alignItems="center" height={theme.custom.layout.headerHeight}>
      <Grid item xs={6} md={6}>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            width: "auto",
            pl: 2,
            pb: "5px",
          }}
        >
          <Link href="/" sx={{ display: "flex", alignItems: "center" }}>
            <img src="/images/logo.png" alt="Logo" style={{ width: "50px" }} />
          </Link>
          <Typography sx={{ paddingRight: "15px", pl: 2 }} variant="subtitle1">
            {getHeaderText()}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} md={6} display="flex" justifyContent="right" alignItems="center" pr={2}>
        {!isAuthenticated && location.pathname !== "/login" && (
          <Link component={RouterLink} to="/login" sx={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary" startIcon={<LoginIcon />} sx={{ marginRight: 2 }}>
              Login
            </Button>
          </Link>
        )}
        {isAuthenticated && (
          <>
            <Button
              variant="contained"
              startIcon={<StarIcon />}
              sx={{ marginRight: 2 }}
              onClick={handleClickExpertButton}
            >
              Become an expert
            </Button>
            <Button variant="contained" startIcon={<LogoutIcon />} onClick={handleLogout}>
              Logout
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
