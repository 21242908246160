import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Box, Grid, Paper, styled, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import ChatBox from "../../../../shared/chat-box";
import { addMessage, chatRequest, resetMessages } from "../../../../../store/profile";
import "./profile.scss";

const predefinedAnswers: Array<string> = [];

interface ProfileProps {
  show: boolean;
}

const Profile: React.FC<ProfileProps> = ({ show }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const profile = useAppSelector((state) => state?.profile.profile);
  const messages = useAppSelector((state) => state?.profile.messages || []);
  const error = useAppSelector((state) => state?.profile.error);
  const status = useAppSelector((state) => state?.profile.status);
  const uuid = useAppSelector((state) => state?.auth.uuid);
  const [message, setMessage] = useState<string>("");

  const handleMessageChange = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (uuid && profile) {
      dispatch(addMessage({ text: message, isAnswer: false }));
      const response = await dispatch(chatRequest({ uuid, question: message, profileId: profile.id }));
      if (chatRequest.fulfilled.match(response)) {
        setMessage("");
      } else if (chatRequest.rejected.match(response)) {
        showSnackbarMessage(`Failed to send message: ${error}`, "error");
      }
    }
  }, [dispatch, message, uuid, showSnackbarMessage, error, profile]);

  useEffect(() => {
    if (profile) {
      dispatch(resetMessages());
      dispatch(
        addMessage({
          text: `Great, now that you’ve selected this candidate ${profile.name}, let me know if you have any questions about this person or about how our platform works, otherwise, feel free to book a message or meeting.`,
          isAnswer: true,
        }),
      );
    }
  }, [profile]);

  if (!show) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{ p: 3 }} overflow="auto">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={messages}
          message={message}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={status === "loading"}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <Box sx={{ p: 2 }}>
            {profile && (
              <Grid container spacing={2}>
                <Grid item xs={12} container alignItems="top">
                  <Grid item>
                    <Avatar sx={{ width: 50, height: 50 }} />
                  </Grid>
                  <Grid item xs ml={2}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main} fontWeight="bold">
                      {profile.name}
                    </Typography>
                    <Typography variant="body2">{profile.location}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" fontStyle="italic" fontWeight="bold">
                    {profile.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="primary" fontWeight="bold">
                    Relevant Experience:
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {profile.relevant_resume_details}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="primary" fontWeight="bold">
                    Questions to ask {profile.name}:
                  </Typography>
                  {profile.top_question.map((question, index) => (
                    <Typography variant="body2" color="textPrimary" key={index}>
                      <b>{index + 1}.</b> {question}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {profile.services.map((service, index) => {
                    return service?.category?.toLowerCase() === "expert connect" ? (
                      <Grid item xs={12} key={index}>
                        <Typography variant="subtitle2" color="primary" fontWeight="bold">
                          {service.category}
                        </Typography>
                        {service.items.map((item, idx) => (
                          <Paper elevation={1} sx={{ p: 2, mb: 1 }} key={idx}>
                            <Grid container alignItems="center">
                              <Grid item xs={8}>
                                <Typography variant="body2" color="textPrimary" key={index} fontWeight="bold">
                                  {item.name}:
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="body2" color="textPrimary" key={index} align="right">
                                  {item.rate}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                      </Grid>
                    ) : null;
                  })}
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Profile;
