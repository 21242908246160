import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { upload } from "../api/upload";
import { UserBio } from "../models";

export const uploadCv = createAsyncThunk(
  "upload/cv",
  async ({ uuid, file }: { uuid: string; file: File }, { rejectWithValue }) => {
    return await upload(uuid, file);
  },
);

export interface UploadResponse {
  filename: string | null;
  status: string | null;
  answer: string | null;
  user_bio: UserBio | null;
}

interface UploadState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  file: File | null;
  response: UploadResponse | null;
}

const initialState: UploadState = {
  status: "idle",
  error: null,
  response: null,
  file: null,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setFile(state, action: PayloadAction<File | null>) {
      state.file = action.payload;
    },
    setResponse(state, action: PayloadAction<UploadResponse | null>) {
      state.response = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    resetAll(state) {
      state.error = null;
      state.response = null;
      state.file = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadCv.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadCv.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(uploadCv.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action?.payload?.error?.message;
      });
  },
});

export const { setFile, setResponse, setError, resetAll: resetUpload } = uploadSlice.actions;
export default uploadSlice.reducer;
