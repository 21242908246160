import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

interface AuthState {
  uuid: string | null;
  token: string | null;
  isAuthenticated: boolean;
  error: string | null;
}

const initialState: AuthState = {
  uuid: localStorage.getItem("uuid") || null,
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  error: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: { username: string; password: string }, { rejectWithValue }) => {
    const { username, password } = credentials;
    if (username === "user" && password === "user") {
      const uuid = uuidv4();
      const token = Math.random().toString(36).substr(2);
      localStorage.setItem("token", token);
      localStorage.setItem("uuid", uuid);
      return token;
    } else {
      return rejectWithValue("Invalid credentials");
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("uuid");
      state.token = null;
      state.isAuthenticated = false;
      state.error = null;
    },
    setUUID: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
        state.token = action.payload;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const { logout, setUUID } = authSlice.actions;
export default authSlice.reducer;
