import React, { useRef, useState } from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import "./upload.scss";

interface UploadProps {
  label?: string;
  dragActive: boolean;
  onDrag: React.DragEventHandler<HTMLDivElement>;
  onDrop: React.DragEventHandler<HTMLDivElement>;
  onRemove: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  file: File | null;
}

const Upload: React.FC<UploadProps> = (props) => {
  const { dragActive, file, label = "Drag and drop here", onDrop, onDrag, onChange, onRemove } = props;
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    onDrop(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const handleRemove = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      onRemove();
    }
  };

  return (
    <Box
      component="div"
      onDragEnter={onDrag}
      onDragLeave={onDrag}
      onDragOver={onDrag}
      onDrop={handleDrop}
      onClick={!file ? handleClick : undefined}
      sx={{
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: "4px",
        padding: "20px",
        mt: 4,
        textAlign: "center",
        cursor: !file ? "pointer" : "default",
        backgroundColor: dragActive ? theme.palette.secondary.dark : theme.palette.secondary.main,
        position: "relative",
      }}
    >
      {!file && (
        <>
          <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />
          <Typography variant="subtitle2" component="p" sx={{ marginTop: 2 }}>
            {label}
          </Typography>
        </>
      )}
      {file && (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
          <Typography variant="subtitle2" component="p" sx={{ marginTop: 2 }}>
            {file.name}
          </Typography>
          <IconButton
            onClick={handleRemove}
            sx={{
              marginTop: 2,
              color: theme.palette.error.main,
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      )}
      <input type="file" style={{ display: "none" }} onChange={handleChange} ref={inputRef} />
    </Box>
  );
};

export default Upload;
