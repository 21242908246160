import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepsState {
  expert: {
    steps: string[];
    currentStep: number;
    totalSteps: number;
    permitted: boolean[];
    view: {
      account: "rates" | "calendar" | "payment" | null;
    };
  };
  connection: {
    steps: string[];
    currentStep: number;
    totalSteps: number;
    permitted: boolean[];
    view: {
      review: "profiles" | "profile" | null;
    };
  };
}

const initialState: StepsState = {
  expert: {
    currentStep: 0,
    totalSteps: 4,
    steps: ["Upload", "Refine", "Account", "Share"],
    permitted: [true, false, false, false],
    view: {
      account: "rates",
    },
  },
  connection: {
    currentStep: 0,
    totalSteps: 4,
    steps: ["Search", "Review", "Account", "Book"],
    permitted: [true, false, false, false],
    view: {
      review: "profiles",
    },
  },
};

const expertStepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    nextStep(state, action: PayloadAction<{ type: "expert" | "connection" }>) {
      const section = state[action.payload.type];
      if (section.currentStep < section.totalSteps - 1) {
        section.currentStep += 1;
      }
    },
    previousStep(state, action: PayloadAction<{ type: "expert" | "connection" }>) {
      const section = state[action.payload.type];
      if (section.currentStep > 0) {
        section.currentStep -= 1;
      }
    },
    setStep(state, action: PayloadAction<{ type: "expert" | "connection"; step: number }>) {
      const section = state[action.payload.type];
      if (action.payload.step >= 0 && action.payload.step < section.totalSteps) {
        section.currentStep = action.payload.step;
      }
    },
    setTotalSteps(state, action: PayloadAction<{ type: "expert" | "connection"; totalSteps: number }>) {
      const section = state[action.payload.type];
      section.totalSteps = action.payload.totalSteps;
    },
    setPermitted(state, action: PayloadAction<{ type: "expert" | "connection"; step: number; permitted: boolean }>) {
      const section = state[action.payload.type];
      if (action.payload.step >= 0 && action.payload.step < section.totalSteps) {
        section.permitted[action.payload.step] = action.payload.permitted;
      }
    },
    setExpertAccountView(state, action: PayloadAction<"rates" | "calendar" | "payment" | null>) {
      state.expert.view.account = action.payload;
    },
    setConnectionReviewView(state, action: PayloadAction<"profiles" | "profile" | null>) {
      state.connection.view.review = action.payload;
    },
  },
});

export const {
  nextStep,
  previousStep,
  setStep,
  setTotalSteps,
  setPermitted,
  setExpertAccountView,
  setConnectionReviewView,
} = expertStepsSlice.actions;
export default expertStepsSlice.reducer;
