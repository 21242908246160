import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShareResponse } from "../models";
import { summary } from "../api/summary";

interface SummaryState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  response: ShareResponse | null;
}

export const fetchSummary = createAsyncThunk("summary/request", async (uuid: string, { rejectWithValue }) => {
  try {
    return await summary(uuid);
  } catch (error: any) {
    return rejectWithValue(error.response);
  }
});

const initialState: SummaryState = {
  status: "idle",
  error: null,
  response: null,
};

const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummary.pending, (state, action: PayloadAction<any>) => {
        state.status = "loading";
      })
      .addCase(fetchSummary.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(fetchSummary.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      });
  },
});

export const {} = summarySlice.actions;
export default summarySlice.reducer;
