import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { chat } from "../api/chat";
import {Message, Service, UserBio} from "../models";

export type Model = "gpt" | "gemini";

export interface ChatRequest {
  question?: string;
  model_name?: Model;
  clear_context?: boolean;
  prompt_type?: PromptType;
  confirm?: boolean;
}

export interface ChatResponse {
  answer?: string;
  title?: string;
  processed?: boolean;
  user_bio?: UserBio;
  services?: Array<Service>;
}

export type PromptType = "bio" | "rates";

interface ChatState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  response: ChatResponse | null;
  messages: Array<Message>;
}

export const chatRequest = createAsyncThunk(
  "chat/request",
  async ({ uuid, body, type }: { uuid: string; body: ChatRequest; type?: "cv" | "llm" }, { rejectWithValue }) => {
    try {
      return await chat(uuid, body, type);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const initialState: ChatState = {
  status: "idle",
  error: null,
  response: null,
  messages: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<Message>) {
      if (state.messages) {
        state.messages.push(action.payload);
      }
    },
    resetMessages(state) {
      state.messages = [];
    },
    resetAll(state) {
      state.status = "idle";
      state.error = null;
      state.response = null;
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chatRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(chatRequest.fulfilled, (state, action: PayloadAction<ChatResponse>) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(chatRequest.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      });
  },
});

export const { addMessage, resetMessages, resetAll: resetChat } = chatSlice.actions;
export default chatSlice.reducer;
