// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  height: calc(100% - 50px);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/main/main.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,yBAAA;AACF","sourcesContent":[".main-container {\n  display: flex;\n  flex-direction: column;\n  padding: 20px 0 0;\n  height: calc(100% - 50px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
