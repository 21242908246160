import React, { useEffect } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import ChatBox from "../../../shared/chat-box";
import "./book.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store";

const predefinedAnswers: string[] = [];

const Book: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentStep = useAppSelector((state) => state?.steps.connection.currentStep);

  const handleMessageChange = () => {};
  const handleSendMessage = () => {};

  useEffect(() => {
    if (currentStep !== 3) {
      navigate("/connection/account");
    }
  }, []);

  return (
    <Grid container item spacing={2} sx={{ p: 3 }} overflow="auto" height="100%">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={[
            {
              isAnswer: true,
              text:
                "Thank you for setting up your account. Please select a date and time that you would like to meet with the expert.",
            },
          ]}
          message={""}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={false}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              padding: "16px",
            }}
          >
            <img
              src="/images/calendar.png"
              alt="Calendar"
              style={{ height: "100%", width: "100%", backgroundSize: "cover" }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Book;
