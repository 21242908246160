import React, { useState } from "react";
import { Box, Typography, Paper, IconButton, Chip, useTheme, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Input from "../input";
import useScrollToBottom from "../../../hooks/useScrollToBottom";
import { Message } from "../../../models";

interface ChatBoxProps {
  messages: Array<Message>;
  message: string;
  predefinedAnswers: string[];
  onNewMessageChange: (message: string) => void;
  onSendMessage: () => void;
  loading?: boolean;
}

const ChatBox: React.FC<ChatBoxProps> = (props) => {
  const theme = useTheme();
  const { messages, message, predefinedAnswers, loading = false, onNewMessageChange, onSendMessage } = props;
  const messagesEndRef = useScrollToBottom([messages]);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  const handleSelectAnswer = (answer: string) => {
    let newAnswers: string[];
    if (selectedAnswers.includes(answer)) {
      newAnswers = selectedAnswers.filter((item) => item !== answer);
    } else {
      newAnswers = [...selectedAnswers, answer];
    }
    setSelectedAnswers(newAnswers);
    onNewMessageChange(newAnswers.join(", "));
  };

  const handleMessageChange = (newMessage: string) => {
    const words = newMessage.split(" ");
    const newAnswers = selectedAnswers.filter((answer) => words.includes(answer));
    setSelectedAnswers(newAnswers);
    onNewMessageChange(newMessage);
  };

  const handleSendMessage = () => {
    if (message) {
      setSelectedAnswers([]);
      onSendMessage();
    }
  };

  const renderMessageText = (text: string) => {
    const parts = text.split(/(\*\*[^\*]+\*\*)/);
    let removedNumberAndDot = "";

    return parts.map((part, index) => {
      const isBold = part.startsWith("**") && part.endsWith("**");
      const previousPart = parts[index - 1] || "";
      let shouldAddNewLine = false;
      const match = part.match(/(\d+\.\s*)$/);
      if (match) {
        removedNumberAndDot = match[1];
        part = part.replace(/(\d+\.\s*)$/, "");
        shouldAddNewLine = true;
      } else if (!match) {
        shouldAddNewLine = isBold && (index === 0 || /[.;]\s*$/.test(previousPart.trim()));
      }

      if (isBold) {
        const boldText = part.substring(2, part.length - 2);

        if (shouldAddNewLine) {
          const jsx = (
            <React.Fragment key={index}>
              <br />
              <br />
              {removedNumberAndDot}
              <strong>{boldText}</strong>
            </React.Fragment>
          );
          removedNumberAndDot = "";
          return jsx;
        } else {
          return <strong key={index}>{boldText}</strong>;
        }
      }

      return <span key={index}>{part}</span>;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: "16px",
          backgroundColor: "#f5f5f5",
        }}
      >
        {messages.map((message, index) => (
          <Paper
            key={index}
            sx={{
              padding: "8px",
              marginBottom: "8px",
              backgroundColor: message.isAnswer ? theme.palette.background.paper : theme.palette.secondary.dark,
            }}
          >
            <Typography variant="body2">{renderMessageText(message.text)}</Typography>
          </Paper>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      {predefinedAnswers.length > 0 && (
        <Box sx={{ padding: "8px", borderBottom: "1px solid #ccc" }}>
          {predefinedAnswers.map((answer) => (
            <Chip key={answer} label={answer} onClick={() => handleSelectAnswer(answer)} sx={{ margin: "4px" }} />
          ))}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          padding: "16px",
          borderTop: "1px solid #ccc",
        }}
      >
        <Input
          fullWidth={true}
          variant="outlined"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => handleMessageChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          rightIcon={
            <IconButton color="primary" onClick={handleSendMessage} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <SendIcon />}
            </IconButton>
          }
        />
      </Box>
    </Box>
  );
};

export default ChatBox;
