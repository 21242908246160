import apiClient from "../api/api-client";
import { ChatResponse, ProfilesResponse } from "../store/profile-review";

export const chat = async (uuid: string, profileId?: string, question?: string): Promise<ChatResponse> => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({ question }),
  };

  const url = `/v1/search/${uuid}/cv/${profileId ? profileId + "/" : ""}chat`;
  return apiClient(url, options);
};

export const reviewStartMessage = async (uuid: string): Promise<ChatResponse> => {
  const options: RequestInit = {
    method: "GET",
  };

  return apiClient(`/v1/search/${uuid}/cv/chat/start`, options);
};

export const profiles = async (uuid: string, offset: number, limit: number): Promise<ProfilesResponse> => {
  const options: RequestInit = {
    method: "GET",
  };
  const query = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
  }).toString();

  return apiClient(`/v1/search/${uuid}/cv/search?${query}`, options);
};
