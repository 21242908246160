import apiClient from "../api/api-client";
import { ServicesResponse } from "../store/service";

export const services = async (uuid: string, question?: string, search?: string): Promise<ServicesResponse> => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      question,
      search,
    }),
  };

  return apiClient(`/v1/search/${uuid}/services`, options);
};
