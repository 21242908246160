import React, { useState } from "react";
import { Box, CircularProgress, Container, Grid, IconButton, Paper, Typography, useTheme } from "@mui/material";
import Input from "../../../shared/input";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { fetchServices, setSearchQuery } from "../../../../store/service";
import "./form.scss";

interface Category {
  title: string;
  description: string;
}

const categories: Category[] = [
  {
    title: "Expert Connect (Connect with an expert)",
    description: "I need to speak with someone that deeply understands a specific industry",
  },
  {
    title: "Freelancing (Connect with a service provider)",
    description: "I need to find someone who can perform specific tasks",
  },
  {
    title: "Staffing as a Service (Find Talent)",
    description: "I am looking for someone qualified for a full time role or contract opportunities",
  },
  {
    title: "Consulting as a service (CaaS)",
    description: "I need to solve a problem and will likely need multiple people to collaborate",
  },
];

const Form: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const uuid = useAppSelector((state) => state.auth.uuid);
  const service = useAppSelector((state) => state.service);
  const [query, setQuery] = useState<string>("");

  const handleSearch = async () => {
    if (uuid && service.status !== "loading") {
      const response = await dispatch(fetchServices({ uuid, search: query }));
      if (fetchServices.fulfilled.match(response)) {
        dispatch(setSearchQuery(query));
        queueMicrotask(() => {
          navigate("/connection/search");
        });
      } else if (fetchServices.rejected.match(response)) {
        showSnackbarMessage(`File to search: ${service.error}`, "error");
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setQuery(query);
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        px: 3,
        py: 10,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: 1,
        mx: 2,
        my: 6,
      }}
    >
      <Grid container spacing={0} display="flex" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
            Welcome,{" "}
            <Typography variant="h4" component="span" sx={{ color: theme.palette.accent1.main }} fontWeight="bold">
              what can we help you with?
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Input
            fullWidth={true}
            placeholder="Type your message here"
            value={query}
            onChange={handleSearchChange}
            sx={{ mb: 16, mt: 4, borderColor: theme.palette.primary.main, borderRadius: "15px" }}
            rightIcon={
              <IconButton color="primary" onClick={handleSearch}>
                {service.status === "loading" ? (
                  <CircularProgress size={24} />
                ) : (
                  <ArrowUpwardIcon
                    sx={{
                      fontSize: "1.7rem",
                      stroke: "currentColor",
                      strokeWidth: 2,
                    }}
                  />
                )}
              </IconButton>
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            inputProps={{
              style: {
                borderRadius: 40,
                paddingLeft: 5,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} display="flex" justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <Grid container spacing={3} display="flex" justifyContent="center" alignItems="stretch">
            {categories.map((category) => (
              <Grid item xs={12} sm={6} key={category.title} sx={{ display: 'flex' }}>
                <Paper
                  elevation={0}
                  sx={{
                    px: 2,
                    py: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: theme.palette.background.paper,
                    "&:hover": { backgroundColor: theme.palette.background.default },
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                    borderStyle: "solid",
                    flexGrow: 1,
                  }}
                >
                  <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {category.title}
                    </Typography>
                    <Typography variant="body2">{category.description}</Typography>
                  </Box>
                  <Box sx={{ visibility: 'hidden', height: '1em' }}>
                    Placeholder text to ensure equal box height
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
