import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Grid } from "@mui/material";
import Calendar from "./calendar";
import Payment from "./payment";
import Rates from "./rates";
import { previousStep, setPermitted } from "../../../../store/steps";
import "./account.scss";

const Account: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const uploadResponse = useAppSelector((state) => state.upload.response);
  const chat = useAppSelector((state) => state.chat);
  const currentView = useAppSelector((state) => state.steps.expert.view.account);

  useEffect(() => {
    if (!chat?.response?.answer) {
      navigate("/expert/refine");
      dispatch(setPermitted({ step: 3, permitted: false, type: "expert" }));
      dispatch(previousStep({ type: "expert" }));
    }
  }, [uploadResponse, navigate, dispatch, chat.response]);

  return (
    <Grid container height="100">
      {currentView === "rates" && <Rates />}
      {currentView === "calendar" && <Calendar />}
      {currentView === "payment" && <Payment />}
    </Grid>
  );
};

export default Account;
