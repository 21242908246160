import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import ChatBox from "../../../../shared/chat-box";
import "./calendar.scss";

const predefinedAnswers: string[] = [];

const Calendar: React.FC = () => {
  const theme = useTheme();

  const handleMessageChange = () => {
    //
  };

  const handleSendMessage = () => {
    //
  };

  return (
    <Grid container item spacing={2} sx={{ p: 3 }} overflow="auto" height="100%">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={[
            {
              isAnswer: true,
              text:
                "Please select the dates and times that you will be available to connect.",
            },
          ]}
          message={""}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={false}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ p: 2, overflow: "auto" }}>
            <img src="/images/expert-calendar.png" alt="Calendar" style={{ width: "100%", backgroundSize: "cover" }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Calendar;
