import React from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import Form from "./form";
import SearchIcon from "@mui/icons-material/Search";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LaptopIcon from "@mui/icons-material/Laptop";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BarChartIcon from "@mui/icons-material/BarChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const Welcome: React.FC = () => {
  const theme = useTheme();

  const handleScrollToTop = () => {
    const appDiv = document.querySelector(".app");
    if (appDiv) {
      appDiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Form />
      <Box
        sx={{
          textAlign: "start",
          px: 3,
          py: 20,
          backgroundColor: theme.palette.background.default,
          boxShadow: 3,
          my: 4,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ mb: 8 }}>
            <Typography variant="h3" sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
              How Leveren works,{" "}
              <Typography variant="h3" component="span" sx={{ color: theme.palette.accent1.main }} fontWeight="bold">
                step by step
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="left" width="330px">
              <Box textAlign="center">
                <SearchIcon sx={{ fontSize: 110 }} />
              </Box>
              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Describe your problem
              </Typography>
              <Typography variant="subtitle2">
                Enter your business problem or area of interest into the search bar
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="left" width="330px">
              <Box textAlign="center">
                <AutoAwesomeIcon sx={{ fontSize: 110 }} />
              </Box>

              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Collaborate with our AI
              </Typography>
              <Typography variant="subtitle2">
                Our AI, Levera, will work with you to suggest the most relevant services or profiles
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="left" width="330px">
              <Box textAlign="center">
                <LaptopIcon sx={{ fontSize: 110 }} />
              </Box>

              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Connect with an expert
              </Typography>
              <Typography variant="subtitle2">
                Message, schedule a video-call with an expert or select a freelance service
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="left" width="330px">
              <Box textAlign="center">
                <SettingsIcon sx={{ fontSize: 110 }} />
              </Box>

              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Establish your account
              </Typography>
              <Typography variant="subtitle2">
                Pay per service or create your account to seamlessly connect going forward
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          p: 3,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          boxShadow: 1,
          mx: 2,
          my: 10,
        }}
      >
        <Grid container spacing={4} sx={{ height: "100%" }}>
          <Grid item xs={6}>
            <Box sx={{ width: "100%", marginRight: "auto" }}>
              <img
                alt="Video call"
                src="/images/discuss.png"
                style={{
                  width: "100%",
                  minHeight: "470px",
                  minWidth: "470px",
                  maxHeight: "600px",
                  borderRadius: theme.shape.borderRadius,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.primary.main, marginBottom: "40px", fontWeight: "bold" }}
              >
                Expand your network{" "}
                <Typography variant="h3" component="span" sx={{ color: theme.palette.accent1.main }} fontWeight="bold">
                  of advisors with Leveren to:
                </Typography>
              </Typography>
              <Box sx={{ mt: theme.spacing(2) }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(4) }}>
                  <AssignmentTurnedInIcon
                    sx={{ fontSize: 110, mr: theme.spacing(2), color: theme.palette.text.primary }}
                  />
                  <Box>
                    <Typography variant="h4" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                      Make Informed Decisions
                    </Typography>
                    <Typography variant="subtitle2">
                      Gain insights from industry experts to confidently navigate business challenges
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(4) }}>
                  <BarChartIcon sx={{ fontSize: 110, mr: theme.spacing(2), color: theme.palette.text.primary }} />
                  <Box>
                    <Typography variant="h4" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                      Accelerate Growth
                    </Typography>
                    <Typography variant="subtitle2">
                      Leverage expert knowledge to fast-track your business development and scale
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(4) }}>
                  <MonetizationOnIcon sx={{ fontSize: 110, mr: theme.spacing(2), color: theme.palette.text.primary }} />
                  <Box>
                    <Typography variant="h4" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                      Manage Cost
                    </Typography>
                    <Typography variant="subtitle2">
                      Access a diverse pool of experts and services tailored to your specific needs, ensuring
                      cost-effectiveness
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: theme.spacing(4) }}>
              <Button variant="contained" color="primary" onClick={handleScrollToTop}>
                Let's get started
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Welcome;
