import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Box, Card, CardContent, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { Profile } from "../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { addMessage, chatRequest, fetchProfiles, setProfiles } from "../../../../../store/profile-review";
import ChatBox from "../../../../shared/chat-box";
import { setProfile } from "../../../../../store/profile";
import "./profiles.scss";
import { setConnectionReviewView } from "../../../../../store/steps";

const predefinedAnswers: Array<string> = [];
const LIMIT: number = 3;

interface ProfilesProps {
  show: boolean;
}

const Profiles: React.FC<ProfilesProps> = ({ show }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const messages = useAppSelector((state) => state?.profileReview.messages || []);
  const profiles = useAppSelector((state) => state?.profileReview?.profiles || []);
  const error = useAppSelector((state) => state?.profileReview.error);
  const status = useAppSelector((state) => state?.profileReview.status);
  const uuid = useAppSelector((state) => state?.auth.uuid);
  const boxRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const currentStep = useAppSelector((state) => state?.steps.connection.currentStep);
  const [hasMoreProfiles, setHasMoreProfiles] = useState<boolean>(true);

  const handleMessageChange = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (uuid) {
      dispatch(addMessage({ text: message, isAnswer: false }));
      const response = await dispatch(chatRequest({ uuid, question: message }));
      if (chatRequest.fulfilled.match(response)) {
        dispatch(setProfiles([]));
        setOffset(0);
        setMessage("");
        const response = await dispatch(fetchProfiles({ uuid, question: message, offset: 0, limit: LIMIT }));
        if (fetchProfiles.fulfilled.match(response)) {
        } else if (fetchProfiles.rejected.match(response)) {
          showSnackbarMessage(`Failed to fetch profiles: ${error}`, "error");
        }
      } else if (chatRequest.rejected.match(response)) {
        showSnackbarMessage(`Failed to send message: ${error}`, "error");
      }
    }
  }, [dispatch, message, uuid, offset, showSnackbarMessage, error]);

  const handleScroll = () => {
    if (boxRef.current && hasMoreProfiles) {
      const bottom = boxRef.current.scrollHeight - boxRef.current.scrollTop === boxRef.current.clientHeight;
      if (bottom) {
        setOffset((prevOffset) => prevOffset + LIMIT);
      }
    }
  };

  const handleProfileClick = (profile: Profile) => {
    dispatch(setProfile(profile));
    dispatch(setConnectionReviewView("profile"));
  };

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.addEventListener("scroll", handleScroll);

      return () => {
        if (boxRef.current) {
          boxRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [boxRef, hasMoreProfiles]);

  useEffect(() => {
    const fetchMoreProfiles = async () => {
      if (uuid && currentStep === 1) {
        const response = await dispatch(fetchProfiles({ uuid, offset, limit: LIMIT }));
        if (fetchProfiles.fulfilled.match(response)) {
          if ((response.payload?.profiles?.length ?? 0) < LIMIT) {
            setHasMoreProfiles(false);
          }
        } else if (fetchProfiles.rejected.match(response)) {
          showSnackbarMessage(`Failed to load more profiles: ${error}`, "error");
          setHasMoreProfiles(false);
        }
      }
    };
    if (hasMoreProfiles && status !== "loading-profiles") {
      fetchMoreProfiles();
    }
  }, [offset, uuid, hasMoreProfiles, dispatch, showSnackbarMessage, error]);

  if (!show) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{ p: 3 }} overflow="auto">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={messages}
          message={message}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={status === "loading-chat"}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          ref={boxRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <Box sx={{ p: 2, height: profiles.length ? "auto" : "100%",  }}>
            {profiles.map((profile, index) => {
              const { name, title, location, description, discuss } = profile;
              return (
                <Card
                  key={index}
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: theme.palette.background.paper,
                    marginBottom: index !== profiles.length - 1 ? 2 : 0,
                    cursor: "pointer",
                  }}
                  onClick={() => handleProfileClick(profile)}
                >
                  <CardContent sx={{ width: "100%" }}>
                    <Grid container direction="column" spacing={2} width="100%">
                      <Grid item xs={12} container alignItems="top">
                        <Grid item xs={12} container alignItems="top">
                          <Grid item>
                            <Avatar sx={{ width: 50, height: 50 }} />
                          </Grid>
                          <Grid item xs ml={2}>
                            <Typography variant="subtitle2" color={theme.palette.primary.main}>
                              {profile.name}
                            </Typography>
                            <Typography variant="body2">{profile.location}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 1 }}>
                          <Typography variant="body2" fontStyle="italic" fontWeight="bold">
                            {profile.description}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textPrimary">
                          {discuss}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
            {status === "loading-profiles" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  height: profiles.length ? "auto" : "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Profiles;
