import React from "react";
import "./about.scss";

interface AboutProps {
  //
}

const About: React.FC<AboutProps> = () => {
  return <div className="about-container">About</div>;
};

export default About;
