import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@mui/material";
import ChatBox from "../../../shared/chat-box";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { addMessage, chatRequest, ChatRequest, ChatResponse, resetMessages } from "../../../../store/chat";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { nextStep, setPermitted } from "../../../../store/steps";
import ProfileInfo from "../../../shared/profile-info";
import { UserBio } from "../../../../models";
import { filterNullProperties } from "../../../../utils";

const predefinedAnswers: string[] = [];

const Refine: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const uploadResponse = useAppSelector((state) => state.upload.response);
  const chat = useAppSelector((state) => state.chat);
  const uuid = useAppSelector((state) => state.auth.uuid);

  const [message, setMessage] = useState<string>("");
  const [profile, setProfile] = useState<UserBio | null>(null);

  useEffect(() => {
    if (!uploadResponse?.answer) {
      navigate("/expert/upload");
    } else if (uploadResponse?.answer) {
      setProfile(uploadResponse.user_bio);

      if (!chat.messages.length) {
        dispatch(addMessage({ isAnswer: true, text: uploadResponse.answer }));
      }
    }
  }, [uploadResponse, navigate, dispatch]);

  const handleMessageChange = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (uuid && message.trim() !== "") {
      dispatch(addMessage({ isAnswer: false, text: message }));

      const body: ChatRequest = { question: message, prompt_type: "bio" };
      const response = await dispatch(chatRequest({ uuid, body }));

      if (chatRequest.fulfilled.match(response)) {
        const payload = response.payload as ChatResponse;

        if (payload.answer) {
          dispatch(addMessage({ isAnswer: true, text: payload.answer }));
        }
        if (payload.user_bio) {
          setProfile((prevProfile) => ({
            ...prevProfile,
            ...(filterNullProperties(payload.user_bio as UserBio) as UserBio),
          }));
        }
        if (payload.processed) {
          dispatch(resetMessages());
          dispatch(setPermitted({ step: 2, permitted: true, type: "expert" }));
          dispatch(nextStep({ type: "expert" }));
        }
      } else if (chatRequest.rejected.match(response)) {
        showSnackbarMessage(`File send message: ${chat.error}`, "error");
      }
      setMessage("");
    }
  }, [uuid, message, dispatch, showSnackbarMessage, chat.error]);

  return (
    <Grid container spacing={2} sx={{ p: 3 }} overflow="auto">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={chat.messages}
          message={message}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={chat.status === "loading"}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        {/*{*/}
        {/*  title: "Frontend Developer",*/}
        {/*  email: "joe@email.com",*/}
        {/*  short_summary: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio doloribus esse eum inventore minus quidem sint? Accusantium enim molestiae qui",*/}
        {/*  full_name: "Joe",*/}
        {/*  top_skills: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio doloribus esse eum inventore minus quidem sint? Accusantium enim molestiae qui",*/}
        {/*  location: "Armenia"*/}
        {/*}*/}
        <ProfileInfo profile={profile} />
      </Grid>
    </Grid>
  );
};

export default Refine;
