import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store";
import { hideSnackbar } from "../../../store/snackbar";

const SnackbarNotification: React.FC = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useAppSelector((state) => state.snackbar);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
