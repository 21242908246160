import React from "react";
import { Grid, Box, Typography, Button, useTheme, Container } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupIcon from "@mui/icons-material/Group";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { v4 as uuidv4 } from "uuid";
import { resetUpload } from "../../../../store/upload";
import { resetChat } from "../../../../store/chat";
import { setUUID } from "../../../../store/auth";
import { useAppDispatch } from "../../../../store";
import { useNavigate } from "react-router-dom";

const GrowInformation = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickExpertButton = () => {
    const newUUID = uuidv4();
    localStorage.setItem("uuid", newUUID);
    dispatch(setUUID(newUUID));
    dispatch(resetUpload());
    dispatch(resetChat());
    navigate("/expert/upload");
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: 1,
        mx: 2,
        my: 10,
      }}
    >
      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            <img
              src="/images/expend-network.png"
              alt="Video call"
              style={{ width: "100%", minHeight: "470px", minWidth: "470px", maxHeight: "600px", borderRadius: theme.shape.borderRadius }}
            />
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box>
            <Typography
              variant="h3"
              sx={{ color: theme.palette.primary.main, marginBottom: "40px", fontWeight: "bold" }}
            >
              Expand your network{" "}
              <Typography variant="h3" component="span" sx={{ color: theme.palette.accent1.main }} fontWeight="bold">
                and continue to grow:
              </Typography>
            </Typography>
            <Box sx={{ mt: theme.spacing(2) }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(4) }}>
                <AccountBalanceWalletIcon
                  sx={{ fontSize: 110, mr: theme.spacing(2), color: theme.palette.text.primary }}
                />
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                    Earn on your terms
                  </Typography>
                  <Typography variant="subtitle2">
                    Monetize your expertise through flexible opportunities like consultations, freelance projects, and
                    workshops
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(4) }}>
                <GroupIcon sx={{ fontSize: 110, mr: theme.spacing(2), color: theme.palette.text.primary }} />
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                    Expand your reach
                  </Typography>
                  <Typography variant="subtitle2">
                    Connect with a diverse range of clients globally, enhancing your professional network and impact
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(4) }}>
                <TrendingUpIcon sx={{ fontSize: 110, mr: theme.spacing(2), color: theme.palette.text.primary }} />
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                    Professional growth
                  </Typography>
                  <Typography variant="subtitle2">
                    Gain visibility and credibility in your field by sharing insights and participating in high-impact
                    collaborations
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/*<Box>*/}
          {/*  <Typography*/}
          {/*    variant="h4"*/}
          {/*    sx={{ color: theme.palette.primary.main, marginBottom: "40px", fontWeight: "bold" }}*/}
          {/*  >*/}
          {/*    Expand your network{" "}*/}
          {/*    <Typography variant="h4" component="span" sx={{ color: theme.palette.accent1.main }} fontWeight="bold">*/}
          {/*      and continue to grow:*/}
          {/*    </Typography>*/}
          {/*  </Typography>*/}
          {/*  <Box sx={{ mt: theme.spacing(2) }}>*/}
          {/*    <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(2) }}>*/}
          {/*      <AccountBalanceWalletIcon*/}
          {/*        sx={{ fontSize: 50, mr: theme.spacing(2), color: theme.palette.text.primary }}*/}
          {/*      />*/}
          {/*      <Box>*/}
          {/*        <Typography variant="subtitle2" sx={{ fontWeight: theme.typography.fontWeightBold }}>*/}
          {/*          Earn on your terms*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="body2">*/}
          {/*          Monetize your expertise through flexible opportunities like consultations, freelance projects, and*/}
          {/*          workshops*/}
          {/*        </Typography>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}

          {/*    <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(2) }}>*/}
          {/*      < sx={{ fontSize: 50, mr: theme.spacing(2), color: theme.palette.text.primary }} />*/}
          {/*      <Box>*/}
          {/*        <Typography variant="subtitle2" sx={{ fontWeight: theme.typography.fontWeightBold }}>*/}
          {/*          Expand your reach*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="body2">*/}
          {/*          Connect with a diverse range of clients globally, enhancing your professional network and impact*/}
          {/*        </Typography>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}

          {/*    <Box sx={{ display: "flex", alignItems: "center", mb: theme.spacing(2) }}>*/}
          {/*      < sx={{ fontSize: 50, mr: theme.spacing(2), color: theme.palette.text.primary }} />*/}
          {/*      <Box>*/}
          {/*        <Typography variant="subtitle2" sx={{ fontWeight: theme.typography.fontWeightBold }}>*/}
          {/*          Professional growth*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="body2">*/}
          {/*          Gain visibility and credibility in your field by sharing insights and participating in high-impact*/}
          {/*          collaborations*/}
          {/*        </Typography>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: theme.spacing(4) }}>
            <Button variant="contained" color="primary" onClick={handleClickExpertButton}>
              Let's get started
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GrowInformation;
