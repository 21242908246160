import React, { useEffect } from "react";
import { Avatar, Box, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { fetchSummary } from "../../../../store/share";
import ChatBox from "../../../shared/chat-box";
import { Message } from "../../../../models";
import "./share.scss";

const messages: Array<Message> = [
  {
    isAnswer: true,
    text:
      "Hello again, on the right hand side you will see a draft post that can be applied to your relevant social media (e.g., LinkedIn, Instagram, Facebook, X). If you like how it looks feel free to go ahead and press the share button and select which platforms you would like to share with, or use the copy / paste function. Otherwise, feel free to edit directly or chat with me to refine the message",
  },
];

const Share: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const permittedStep = useAppSelector((state) => !!state.steps.expert.permitted[3]);
  const uuid = useAppSelector((state) => state.auth.uuid);
  const summary = useAppSelector((state) => state.share.response?.summary || "");
  const status = useAppSelector((state) => state.share.status);

  useEffect(() => {
    if (!permittedStep) {
      navigate("/expert/account");
    }
  }, [permittedStep]);

  useEffect(() => {
    if (uuid) {
      dispatch(fetchSummary(uuid));
    }
  }, [uuid]);

  return (
    <Grid container spacing={2} sx={{ p: 3 }} overflow="auto">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={messages}
          message={""}
          onNewMessageChange={() => null}
          onSendMessage={() => null}
          predefinedAnswers={[]}
          loading={false}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {status === "loading" ? (
            <Grid item xs={12} container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : (
            <Grid container spacing={2} sx={{ p: 2, overflow: "auto" }}>
              <Grid item xs={12} container alignItems="top" spacing={2}>
                <Grid item xs={12} container alignItems="top">
                  <Grid item>
                    <Avatar sx={{ width: 50, height: 50 }} />
                  </Grid>
                  <Grid item xs ml={2}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Share your Leveren profile
                    </Typography>
                    <Typography variant="body2">Draft post</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ pb: 1, lineHeight: "25px" }}>
                    {summary}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Share;
