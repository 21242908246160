import React, { useEffect } from "react";
import { Stepper as MuiStepper, Step, StepLabel, Typography, Box, Button, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import { resetChat } from "../../../store/chat";
import { resetUpload } from "../../../store/upload";
import StarIcon from "@mui/icons-material/Star";

interface StepperProps {
  activeStep: number;
  steps: string[];
  handleBack: () => void;
  handleNext: () => void;
  handleConfirm: () => Promise<void>;
  handleShare: () => void;
  content: React.ReactNode;
  permitted?: boolean[];
  loading?: boolean;
  buttonsConfig: {
    back?: boolean[];
    next?: boolean[];
    confirm?: boolean[];
    share?: boolean[];
  };
  currentView?: "profiles" | "profile" | null;
  name?: string;
}

const Stepper: React.FC<StepperProps> = ({
  activeStep,
  steps,
  handleBack,
  handleNext,
  handleConfirm,
  handleShare,
  content,
  loading,
  buttonsConfig,
  currentView,
  name,
}) => {
  const uuid = useAppSelector((state) => state.auth.uuid);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!uuid) {
      navigate("/onboarding-steps");
      dispatch(resetUpload());
      dispatch(resetChat());
    }
  }, [uuid, navigate]);

  const isButtonVisible = (buttonType: keyof typeof buttonsConfig) => {
    if ((buttonType === "confirm" || buttonType === "back") && activeStep === 1 && name === "connection") {
      return buttonsConfig[buttonType]?.[activeStep] && currentView === "profile";
    }
    return buttonsConfig[buttonType]?.[activeStep] ?? false;
  };

  return (
    <>
      <MuiStepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </MuiStepper>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, height: "calc(100% - 100px)" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          content
        )}
      </Box>
      {!loading && (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {isButtonVisible("back") && (
            <Button variant="contained" color="primary" onClick={handleBack} sx={{ ml: 1 }}>
              Back
            </Button>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          {isButtonVisible("next") && (
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mr: 1 }}>
              Next
            </Button>
          )}
          {isButtonVisible("confirm") && (
            <Button variant="contained" color="primary" onClick={handleConfirm} sx={{ mr: 1 }}>
              Confirm
            </Button>
          )}
          {isButtonVisible("share") && (
            <Button variant="contained" color="primary" onClick={handleShare} sx={{ mr: 1 }}>
              Share
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default Stepper;
