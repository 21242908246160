import React from "react";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import StarIcon from "@mui/icons-material/Star";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import PublicIcon from "@mui/icons-material/Public";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

const Steps = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: "start",
        px: 3,
        py: 5,
        backgroundColor: theme.palette.background.default,
        boxShadow: 3,
        my: 4,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: 0 }}>
          <Typography variant="h3" sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
            How Leveren works,{" "}
            <Typography variant="h3" component="span" sx={{ color: theme.palette.accent1.main }} fontWeight="bold">
              step by step
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Box textAlign="left" width="340px">
            <Box textAlign="center">
              <UploadIcon sx={{ fontSize: 110, mr: 4 }} />
            </Box>
            <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
              Upload your resume
            </Typography>
            <Typography variant="subtitle2">
              Or provide URLs to your profiles on other platforms (e.g., LinkedIn, Instagram)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Box textAlign="left" width="340px">
            <Box textAlign="center">
              <StarIcon sx={{ fontSize: 110, mr: 8 }} />
            </Box>
            <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
              Refine your profile
            </Typography>
            <Typography variant="subtitle2">
              Our AI, Levera, will suggest potential services (and rates) that you could provide
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Box textAlign="left" width="340px">
            <Box textAlign="center">
              <CalendarTodayIcon sx={{ fontSize: 110, mr: 8 }} />
            </Box>
            <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
              Set your availability
            </Typography>
            <Typography variant="subtitle2">
              Connect your calendar or directly set availability to provide your services{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Box textAlign="left" width="340px">
            <Box textAlign="center">
              <SettingsIcon sx={{ fontSize: 110, mr: 4 }} />
            </Box>
            <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
              Establish your account
            </Typography>
            <Typography variant="subtitle2">
              Provide payment details and complete your account set-up and preferences
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Once completed...
          </Typography>
        </Grid>

        <Grid container spacing={4} display="flex" justifyContent="center">
          <Grid item xs={3} display="flex" justifyContent="center">
            <Box textAlign="left" width="340px">
              <Box textAlign="center">
                <ShareIcon sx={{ fontSize: 110,  mr: 12 }} />
              </Box>
              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Share your profile
              </Typography>
              <Typography variant="subtitle2">
                Connect your Leveren profile with your network to let them know where to book you
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="center">
            <Box textAlign="left" width="340px">
              <Box textAlign="center">
                <PublicIcon sx={{ fontSize: 110 }} />
              </Box>

              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Our database is updated
              </Typography>
              <Typography variant="subtitle2">
                Your profile will now be on our platform, feeding our user search engine
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="center">
            <Box textAlign="left" width="340px">
              <Box textAlign="center">
                <ConnectWithoutContactIcon sx={{ fontSize: 110, mr: 16 }} />
              </Box>

              <Typography variant="h4" fontWeight="bold" sx={{ pb: 3 }}>
                Start connecting
              </Typography>
              <Typography variant="subtitle2">
                Start earning money by responding to messages and accepting invites
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Steps;
