import React from "react";
import "./expert.scss";
import { Box } from "@mui/material";
import Steps from "../welcome/steps";
import GrowInformation from "../welcome/grow-information";

const Expert: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Steps />
      <GrowInformation />
    </Box>
  );
};

export default Expert;
