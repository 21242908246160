import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { addMessage, fetchServices } from "../../../../store/service";
import ChatBox from "../../../shared/chat-box";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import GroupIcon from "@mui/icons-material/Group";
import LaptopIcon from "@mui/icons-material/Laptop";
import WorkIcon from "@mui/icons-material/Work";
import { nextStep } from "../../../../store/steps";
import "./search.scss";

const predefinedAnswers: Array<string> = [];

const Search: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const messages = useAppSelector((state) => state.service?.messages);
  const services = useAppSelector((state) => state.service?.response?.services || []);
  const status = useAppSelector((state) => state.service.status);
  const error = useAppSelector((state) => state.service.error);
  const uuid = useAppSelector((state) => state.auth.uuid);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (!services.length) {
      navigate("/");
    } else {
      //
    }
  }, []);

  const handleMessageChange = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (uuid) {
      dispatch(addMessage({ text: message, isAnswer: false }));
      const response = await dispatch(fetchServices({ uuid, question: message }));
      if (fetchServices.fulfilled.match(response)) {
        setMessage("");
      } else if (fetchServices.rejected.match(response)) {
        showSnackbarMessage(`File to send message: ${error}`, "error");
      }
    }
  }, [message]);

  const handleServiceClick = useCallback(async (name: string) => {
    if (name === "Expert Connect") {
      navigate("/connection/review");
      dispatch(nextStep({ type: "connection" }));
    }
  }, []);

  return (
    <Grid container spacing={2} sx={{ p: 3 }} overflow="auto">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={messages}
          message={message}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={status === "loading"}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ p: 2, overflow: "auto" }}>
            {services.map((service, index) => (
              <Paper
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: index !== services.length - 1 ? 3 : 0,
                  px: 2,
                  py: 3,
                  cursor: service.name === "Expert Connect" ? "pointer" : "default",
                }}
                onClick={() => handleServiceClick(service.name)}
                elevation={3}
              >
                <Box sx={{ width: "70px" }}>
                  {service.name === "Expert Connect" && <VideoCallIcon color="primary" style={{ fontSize: 40 }} />}{" "}
                  {(service.name === "Consulting as a Service (CaaS)" || service.name === "Consulting") && (
                    <GroupIcon color="primary" style={{ fontSize: 40 }} />
                  )}{" "}
                  {service.name === "Freelance Services" && <LaptopIcon color="primary" style={{ fontSize: 40 }} />}{" "}
                  {service.name === "Staffing as a Service (STaaS)" && (
                    <WorkIcon color="primary" style={{ fontSize: 40 }} />
                  )}{" "}
                </Box>
                <Box sx={{ maxWidth: "145px", width: "145px" }}>
                  <Typography variant="body2" sx={{ margin: "0 10px" }}>
                    <b>{service.name} </b>
                  </Typography>
                </Box>
                <Box sx={{width: "100%"}}>
                  <Typography variant="body2">{service.description}</Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Search;
