import React from "react";
import { Grid, Typography, Link, Box, useTheme } from "@mui/material";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import BackgroundSection from "../../shared/background-section";

const ExpertCongratulations = () => {
  const theme = useTheme();

  return (
    <BackgroundSection maxWidth="xl">
      <Grid container display="flex" alignItems="start">
        <Grid item xs={6} container spacing={2} padding={4}>
          <img
            src="/images/developer.png"
            alt="Video call"
            style={{
              width: "100%",
              minHeight: "470px",
              minWidth: "470px",
              borderRadius: theme.shape.borderRadius,
            }}
          />
        </Grid>
        <Grid item xs={6} container spacing={2} padding={4}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              <span style={{ color: theme.palette.primary.main }}>
                Congratulations, your meeting is scheduled for May 7th at 9am!{" "}
              </span>
              <span>Please consider these next steps after your meeting...</span>
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={2} sx={{ mt: 4 }} display="flex" alignItems="center">
            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box>
                  <ThumbUpAltOutlinedIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Rate and review your expert,{" "}
                  </Link>
                  let us know how your session went and help others find the right expert
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box>
                  <DiamondOutlinedIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Upgrade to premium{" "}
                  </Link>
                  to leverage all our AI services to help you grow
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <PermIdentityOutlinedIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Become an expert yourself,{" "}
                  </Link>
                  to share your own experience with others
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BackgroundSection>
  );
};

export default ExpertCongratulations;
