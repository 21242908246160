import apiClient from "../api/api-client";

export const upload = async (uuid: string, file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);

  const options: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: formData,
  };

  return apiClient(`/v1/cv/${uuid}/attachment`, options);
};
