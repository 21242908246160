import React from "react";
import { Box, Breakpoint, Container, Paper } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface BackgroundSectionProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  maxWidth?: Breakpoint;
}

const BackgroundSection: React.FC<BackgroundSectionProps> = ({ children, sx = {}, maxWidth = "lg" }) => {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        position: "relative",
        minHeight: "calc(100% - 50px)",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -1,
          opacity: 0.3,
        }}
      />
      <Paper
        elevation={3}
        sx={{
          p: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          maxHeight: "100vh",
        }}
      >
        {children}
      </Paper>
    </Container>
  );
};

export default BackgroundSection;
