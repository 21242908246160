import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  SelectChangeEvent,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { chatRequest, ChatRequest, Model } from "../../../store/chat";
import { useAppDispatch, useAppSelector } from "../../../store";

const Chat: React.FC = () => {
  const dispatch = useAppDispatch();
  const chat = useAppSelector((state) => state.chat);
  const [uuid, setUuid] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [model, setModel] = useState<Model>("gpt");
  const [rememberContext, setRememberContext] = useState<boolean>(false);
  const [uuidError, setUuidError] = useState<boolean>(false);
  const [questionError, setQuestionError] = useState<boolean>(false);
  const [modelError, setModelError] = useState<boolean>(false);

  const handleUuidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUuid(event.target.value);
    if (event.target.value) setUuidError(false);
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
    if (event.target.value) setQuestionError(false);
  };

  const handleModelChange = (event: SelectChangeEvent<Model>) => {
    setModel(event.target.value as Model);
    if (event.target.value) setModelError(false);
  };

  const handleRememberContextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberContext(event.target.checked);
  };

  const handleSubmit = async () => {
    let valid = true;
    if (!uuid) {
      setUuidError(true);
      valid = false;
    }
    if (!question) {
      setQuestionError(true);
      valid = false;
    }
    if (!model) {
      setModelError(true);
      valid = false;
    }

    if (valid) {
      const body: ChatRequest = {
        question,
        model_name: model,
        clear_context: !rememberContext,
      };
      const response = await dispatch(chatRequest({ uuid, body, type: "llm" }));
      if (chatRequest.fulfilled.match(response)) {
        setQuestion("");
      }
    }
  };

  return (
    <Container>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="UUID"
            value={uuid}
            onChange={handleUuidChange}
            error={uuidError}
            helperText={uuidError ? "UUID is required" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="model-select-label">Model</InputLabel>
            <Select
              labelId="model-select-label"
              id="demo-simple-select"
              label="Model"
              value={model}
              onChange={handleModelChange}
            >
              <MenuItem value="gpt">Gpt</MenuItem>
              <MenuItem value="gemini">Gemini</MenuItem>
            </Select>
            {modelError && <FormHelperText>Model is required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={rememberContext} onChange={handleRememberContextChange} />}
            label="Remember Context"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Enter your question"
            value={question}
            onChange={handleQuestionChange}
            multiline
            rows={4}
            error={questionError}
            helperText={questionError ? "Question is required" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={chat.status === "loading"}>
            {chat.status === "loading" ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Answer:</Typography>
          <Typography variant="body1">{chat.response ? chat.response.answer : ""}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Chat;
