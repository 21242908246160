import { API_URL } from "../constants";

const apiClient = async (endpoint: string, options: RequestInit = {}) => {
  const response = await fetch(`${API_URL}${endpoint}`, {
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
    ...options,
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export default apiClient;

// const searchParams = new URLSearchParams();
// if (question) {
//   searchParams.append("question", question);
// }
// if (search) {
//   searchParams.append("search", search);
// }
// const query = searchParams.toString();
