import React from "react";
import { Box, Typography, Grid, useTheme, Link } from "@mui/material";
import BackgroundSection from "../../shared/background-section";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const ConnectionCongratulations = () => {
  const theme = useTheme();

  return (
    <BackgroundSection maxWidth="xl">
      <Grid container display="flex" alignItems="start">
        <Grid item xs={6} container spacing={2} padding={4}>
          <img
            src="/images/happy-programmer.png"
            alt="Video call"
            style={{
              width: "100%",
              minHeight: "470px",
              minWidth: "470px",
              borderRadius: theme.shape.borderRadius,
            }}
          />
        </Grid>
        <Grid item xs={6} container spacing={2} padding={4}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              <span style={{ color: theme.palette.primary.main }}>Congratulations </span>
              <span>on establishing your profile, here’s what happens next...</span>
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={2} sx={{ mt: 4 }} display="flex" alignItems="center">
            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box>
                  <MailOutlineIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Monitor your email and texts{" "}
                  </Link>
                  for messages or meeting requests from people trying to book you
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box>
                  <AccountCircleOutlinedIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Periodically review your profile{" "}
                  </Link>
                  to see your stats (e.g., how many times you have been viewed)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <DiamondIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Upgrade to premium{" "}
                  </Link>
                  in order to leverage our AI to continually enhance your profile
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <SearchOutlinedIcon sx={{ marginRight: 1, fontSize: 110 }} />
                </Box>
                <Typography variant="h4" sx={{ marginLeft: 1 }}>
                  <Link href="#" sx={{ fontWeight: "bold", textDecoration: "none" }}>
                    Find other experts on the platform,{" "}
                  </Link>
                  that may help you grow your profile
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BackgroundSection>
  );
};

export default ConnectionCongratulations;
