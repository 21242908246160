import React from "react";
import { Grid, Typography, Avatar, Box, useTheme } from "@mui/material";
import { UserBio } from "../../../models";

interface ProfileProps {
  profile: UserBio | null;
}

const ProfileInfo: React.FC<ProfileProps> = ({ profile }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={2} sx={{ p: 2, overflow: "auto" }}>
        <Grid item xs={12} container alignItems="top">
          <Grid item xs={12} container alignItems="top">
            <Grid item>
              <Avatar variant="square" sx={{ width: 55, height: 55 }} src="/images/avatar-logo.svg" />
            </Grid>
            <Grid item xs ml={2}>
              {profile?.full_name && (
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  {profile.full_name}
                </Typography>
              )}
              {profile?.location && <Typography variant="body2">{profile.location}</Typography>}
            </Grid>
          </Grid>
        </Grid>
        {profile?.short_summary && (
          <Grid item xs={12}>
            <Typography variant="body2" fontStyle="italic" fontWeight="bold">
              {profile.short_summary}
            </Typography>
          </Grid>
        )}
        {profile?.top_skills && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Relevant Experience:
            </Typography>
            {profile.top_skills.map((skill, index) => (
              <Typography variant="body2" key={index} sx={{ pb: 1 }}>
                <b>{skill.skill_title}:</b> {skill.skill_description}
              </Typography>
            ))}
          </Grid>
        )}
        {profile?.personality && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              On a personal note, I can also discuss:
            </Typography>
            {profile.personality.map((personality, index) => (
              <Typography variant="body2" key={index}>
                <b>{personality.skill_title}:</b> {personality.skill_description}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProfileInfo;
