import React from "react";
import "./main.scss";

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = (props) => {
  const { children } = props;

  return <div className="main-container">{children}</div>;
};

export default Main;
