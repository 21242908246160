import React, { useState } from "react";
import { Box, Typography, Button, TextField, Grid, CircularProgress, useTheme } from "@mui/material";
import { default as UploadCv } from "../../../shared/upload";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setError, setFile, setResponse, uploadCv } from "../../../../store/upload";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { nextStep, setPermitted } from "../../../../store/steps";
import { useNavigate } from "react-router-dom";
import { resetMessages } from "../../../../store/chat";
import ChatBox from "../../../shared/chat-box";

const predefinedAnswers: string[] = [];

const Upload = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const uuid = useAppSelector((state) => state.auth.uuid || "");
  const status = useAppSelector((state) => state.upload.status);
  const error = useAppSelector((state) => state.upload.error);
  const file = useAppSelector((state) => state.upload.file);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleMessageChange = () => {
    //
  };

  const handleSendMessage = () => {
    //
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      dispatch(setFile(e.dataTransfer.files[0]));
      dispatch(setPermitted({ step: 1, permitted: false, type: "expert" }));
      const resultAction = await dispatch(uploadCv({ uuid, file: e.dataTransfer.files[0] }));
      if (uploadCv.fulfilled.match(resultAction)) {
        showSnackbarMessage("File uploaded successfully!", "success");
        dispatch(setPermitted({ step: 1, permitted: true, type: "expert" }));
        dispatch(nextStep({ type: "expert" }));
        navigate("/expert/refine");
      } else if (uploadCv.rejected.match(resultAction)) {
        showSnackbarMessage(`File upload failed: ${error}`, "error");
      }
    }
  };

  const handleRemove = () => {
    dispatch(setFile(null));
    dispatch(setResponse(null));
    dispatch(setError(null));
    dispatch(setPermitted({ step: 1, permitted: false, type: "expert" }));
    dispatch(resetMessages());
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.target.files && e.target.files[0]) {
      dispatch(setFile(e.target.files[0]));
      dispatch(setPermitted({ step: 1, permitted: false, type: "expert" }));
      const resultAction = await dispatch(uploadCv({ uuid, file: e.target.files[0] }));
      if (uploadCv.fulfilled.match(resultAction)) {
        showSnackbarMessage("File uploaded successfully!", "success");
        dispatch(setPermitted({ step: 1, permitted: true, type: "expert" }));
        dispatch(nextStep({ type: "expert" }));
        navigate("/expert/refine");
      } else if (uploadCv.rejected.match(resultAction)) {
        showSnackbarMessage(`File upload failed: ${error}`, "error");
      }
    }
  };

  return (
    <Grid container spacing={2} sx={{ p: 3 }} overflow="auto">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={[
            {
              isAnswer: true,
              text:
                "Hi, I’m Levera, I’m here to help you get started on your journey to becoming an expert on our platform. Please upload your CV / resume or provide your social media URL link on the right.",
            },
          ]}
          message={""}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={false}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ p: 2, overflow: "auto" }}>
            {status === "loading" && (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 4 }}>
                <CircularProgress />
              </Box>
            )}
            {status !== "loading" && (
              <UploadCv
                file={file}
                dragActive={dragActive}
                onDrag={handleDrag}
                onChange={handleChange}
                onDrop={handleDrop}
                onRemove={handleRemove}
              />
            )}
            {status !== "loading" && !file && (
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={12} md={10}>
                  <TextField fullWidth variant="outlined" placeholder="Linkedin.com" />
                </Grid>
                <Grid item xs={12} md={1} sx={{ display: "flex", alignItems: "center" }}>
                  <Button variant="contained" color="primary" sx={{ height: "100%" }}>
                    Add
                  </Button>
                </Grid>
                <Grid item xs={12} md={12} />
                <Grid item xs={12} md={10} sx={{ mb: 2 }}>
                  <TextField fullWidth variant="outlined" placeholder="Instagram.com" />
                </Grid>
                <Grid item xs={12} md={1} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Button variant="contained" color="primary" sx={{ height: "100%" }}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            )}
            {status === "failed" && (
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                Failed to upload CV. Please try again.
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Upload;
