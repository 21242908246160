import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { profiles, chat, reviewStartMessage } from "../api/profile";
import { Message, Profile } from "../models";

export interface ProfilesResponse {
  profiles?: Array<Profile>;
}

export interface ChatResponse {
  answer?: string;
}

interface ProfilesState {
  status: "idle" | "loading-profiles" | "loading-chat" | "succeeded" | "failed";
  error: string | null;
  profiles: Array<Profile>;
  messages: Array<Message>;
}

export const fetchProfiles = createAsyncThunk(
  "profiles/request",
  async (
    { uuid, question, limit, offset }: { uuid: string; question?: string; offset: number; limit: number },
    { rejectWithValue },
  ) => {
    try {
      return await profiles(uuid, offset, limit);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

export const fetchReviewStartMessage = createAsyncThunk(
  "profiles/start-message/request",
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await reviewStartMessage(uuid);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

export const chatRequest = createAsyncThunk(
  "chat/profile-review/request",
  async (
    { uuid, profileId, question }: { uuid: string; profileId?: string; question?: string },
    { rejectWithValue },
  ) => {
    try {
      return await chat(uuid, profileId, question);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const initialState: ProfilesState = {
  status: "idle",
  error: null,
  profiles: [],
  messages: [],
};

const profileReviewSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<Message>) {
      if (state.messages) {
        state.messages.push(action.payload);
      }
    },
    setProfiles(state, action: PayloadAction<Array<Profile>>) {
      state.profiles = action.payload;
    },
    resetMessages(state) {
      state.messages = [];
    },
    resetAll(state) {
      state.status = "idle";
      state.error = null;
      state.profiles = [];
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chatRequest.pending, (state) => {
        state.status = "loading-chat";
      })
      .addCase(chatRequest.fulfilled, (state, action: PayloadAction<ChatResponse>) => {
        state.status = "succeeded";
        state.messages.push({ text: action.payload.answer || "", isAnswer: true });
      })
      .addCase(chatRequest.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      })
      .addCase(fetchProfiles.pending, (state) => {
        state.status = "loading-profiles";
      })
      .addCase(fetchProfiles.fulfilled, (state, action: PayloadAction<ProfilesResponse>) => {
        state.status = "succeeded";
        if (action.payload.profiles) {
          state.profiles.push(...action.payload.profiles);
        }
      })
      .addCase(fetchProfiles.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      })
      .addCase(fetchReviewStartMessage.pending, (state) => {
        state.status = "loading-chat";
      })
      .addCase(fetchReviewStartMessage.fulfilled, (state, action: PayloadAction<ChatResponse>) => {
        state.messages.push({ text: action.payload.answer || "", isAnswer: true });
        state.status = "succeeded";
      })
      .addCase(fetchReviewStartMessage.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      });
  },
});

export const { resetAll: resetProfiles, resetMessages, addMessage, setProfiles } = profileReviewSlice.actions;
export default profileReviewSlice.reducer;
