import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { hideSnackbar, showSnackbar } from "../store/snackbar";

type Severity = "success" | "error" | "warning" | "info";

export const useSnackbar = () => {
  const dispatch = useDispatch();

  const showSnackbarMessage = useCallback(
    (message: string, severity: Severity = "success") => {
      dispatch(showSnackbar({ message, severity }));
    },
    [dispatch],
  );

  const handleCloseSnackbar = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") return;
      dispatch(hideSnackbar());
    },
    [dispatch],
  );

  return {
    showSnackbarMessage,
    handleCloseSnackbar,
  };
};
