import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Header from "./layout/header";
import Main from "./layout/main";
import PrivateRoute from "./shared/private-route";
import Login from "./pages/login";
import Welcome from "./pages/welcome";
import ExpertSteps from "./pages/expert-steps";
import Upload from "./pages/expert-steps/upload";
import { useAppSelector } from "../store";
import SnackbarNotification from "./shared/snackbar-notification";
import Refine from "./pages/expert-steps/refine";
import Chat from "./pages/chat";
import { default as ExpertAccount } from "./pages/expert-steps/account";
import { default as ConnectionAccount } from "./pages/connection-steps/account";
import Share from "./pages/expert-steps/share";
import ExpertCongratulations from "./pages/expert-congratulations";
import Search from "./pages/connection-steps/search";
import Review from "./pages/connection-steps/review";
import ConnectionSteps from "./pages/connection-steps";
import Book from "./pages/connection-steps/book";
import ConnectionCongratulations from "./pages/connection-congratulations";
import Footer from "./layout/footer";
import Expert from "./pages/expert";

function App() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="app">
      <Router>
        <Header />

        <Main>
          <Routes>
            <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Welcome />} />
              {/*<Route path="/home" element={<Home />} />*/}
              <Route path="/about" element={<About />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/expert" element={<Expert />} />
              <Route path="/expert" element={<ExpertSteps />}>
                <Route path="upload" element={<Upload />} />
                <Route path="refine" element={<Refine />} />
                <Route path="account" element={<ExpertAccount />} />
                <Route path="share" element={<Share />} />
                <Route path="*" element={<Navigate to="/expert/upload" replace />} />
              </Route>
              <Route path="/connection/congratulations" element={<ExpertCongratulations />} />
              <Route path="/connection" element={<ConnectionSteps />}>
                <Route path="" element={<Navigate to="/connection/search" replace />} />
                <Route path="search" element={<Search />} />
                <Route path="review" element={<Review />} />
                <Route path="account" element={<ConnectionAccount />} />
                <Route path="book" element={<Book />} />
                <Route path="*" element={<Navigate to="/connection/search" replace />} />
              </Route>
              <Route path="/expert/congratulations" element={<ConnectionCongratulations />} />
            </Route>
            <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/login"} replace />} />
          </Routes>

          <Footer />
        </Main>
        <SnackbarNotification />
      </Router>
    </div>
  );
}

export default App;
