import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { services } from "../api/service";
import { Message } from "../models";

export interface ServicesResponse {
  answer?: string;
  services?: Array<{
    name: string;
    description: string;
  }>;
}

interface ServicesState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  response: ServicesResponse | null;
  messages: Array<Message>;
  searchQuery: string;
}

export const fetchServices = createAsyncThunk(
  "services/request",
  async ({ uuid, question, search }: { uuid: string; question?: string; search?: string }, { rejectWithValue }) => {
    try {
      return await services(uuid, question, search);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const initialState: ServicesState = {
  status: "idle",
  error: null,
  response: null,
  searchQuery: "",
  messages: [],
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<Message>) {
      if (state.messages) {
        state.messages.push(action.payload);
      }
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    resetMessages(state) {
      state.messages = [];
    },
    resetAll(state) {
      state.status = "idle";
      state.error = null;
      state.response = null;
      state.messages = [];
      state.searchQuery = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServices.fulfilled, (state, action: PayloadAction<ServicesResponse>) => {
        state.status = "succeeded";
        if (!state.response) {
          state.response = {};
        }
        state.response.answer = action.payload.answer;
        state.messages.push({ text: action.payload.answer || "", isAnswer: true });
        if (action.payload.services) {
          state.response.services = action.payload.services;
        }
      })
      .addCase(fetchServices.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      });
  },
});

export const { resetAll: resetServices, addMessage, setSearchQuery } = servicesSlice.actions;
export default servicesSlice.reducer;
