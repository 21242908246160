import { useRef, useEffect } from "react";

const useScrollToBottom = (dependencies: any[], shouldScroll: boolean = true) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom();
    }
  }, [shouldScroll, ...dependencies]);

  return elementRef;
};

export default useScrollToBottom;
