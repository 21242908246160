import { createTheme, PaletteOptions, Palette } from "@mui/material/styles";

export const COLOR1 = "#ef8b01";
export const COLOR2 = "#ffffff";
export const COLOR3 = "#000000";
export const COLOR4 = "#ece3e1";

declare module "@mui/material/styles" {
  interface Palette {
    accent1: Palette["primary"];
    accent2: Palette["primary"];
  }
  interface PaletteOptions {
    accent1?: PaletteOptions["primary"];
    accent2?: PaletteOptions["primary"];
  }
  interface Theme {
    custom: {
      layout: {
        headerHeight: string;
        bodyHeight: string;
      };
    };
  }
  interface ThemeOptions {
    custom?: {
      layout?: {
        headerHeight?: string;
        bodyHeight?: string;
      };
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent1: true;
    accent2: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "Roboto", "Lato", "sans-serif"].join(","),
    h1: {
      fontFamily: "Open Sans",
      fontSize: "2.5rem",
    },
    h2: {
      fontFamily: "Open Sans",
    },
    h4: {
      fontFamily: "Open Sans",
      fontSize: "28px",
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: "19px",
    },
    body2: {
      fontFamily: "Open Sans",
      fontSize: "15px",
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontSize: "22px",
    },
    subtitle2: {
      fontFamily: "Open Sans",
      fontSize: "22px",
    },
    button: {
      fontFamily: "Open Sans",
    },
    caption: {
      fontFamily: "Open Sans, sans-serif",
    },
    overline: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
  palette: {
    primary: {
      main: COLOR1,
    },
    secondary: {
      main: COLOR2,
    },
    accent1: {
      main: COLOR3,
    },
    accent2: {
      main: COLOR4,
    },
    background: {
      default: COLOR4,
      paper: COLOR2,
    },
    text: {
      primary: COLOR3,
      secondary: COLOR1,
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
    info: {
      main: "#2196f3",
    },
    success: {
      main: "#4caf50",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedPrimary: {
          backgroundColor: COLOR2,
          color: COLOR3,
          border: `1px solid ${COLOR1}`,
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
            backgroundColor: COLOR4,
          },
        },
        containedSecondary: {},
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiInputBase-input::placeholder": {
    //         color: "rgba(0, 0, 0, 0.6)",
    //         opacity: 1,
    //       },
    //     },
    //   },
    // },
    MuiFab: {
      styleOverrides: {
        primary: {
          color: COLOR2,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          text: {
            fill: "#fff",
          },
        },
      },
    },
  },
  custom: {
    layout: {
      headerHeight: "60px",
      bodyHeight: "100vh",
    },
  },
});

export default theme;
