import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import ChatBox from "../../../../shared/chat-box";
import "./payment.scss";

const predefinedAnswers: string[] = [];

const Payment: React.FC = () => {
  const theme = useTheme();

  const handleMessageChange = () => {
    //
  };

  const handleSendMessage = () => {
    //
  };

  return (
    <Grid container item spacing={2} sx={{ p: 3 }} overflow="auto" height="100%">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={[
            {
              isAnswer: true,
              text:
                "Please sign-up by creating your own username and password or by logging into your preferred social media account and then you will be guided to provide additional information and payment details.",
            },
          ]}
          message={""}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={false}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "hidden",
              padding: "16px",
            }}
          >
            <img src="/images/register.png" alt="Calendar" style={{ height: "100%", width: "100%" }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Payment;
