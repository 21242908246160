import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import Profiles from "./profiles";
import Profile from "./profile";
import { previousStep } from "../../../../store/steps";
import { useNavigate } from "react-router-dom";
import { fetchReviewStartMessage } from "../../../../store/profile-review";
import "./review.scss";

const Review: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector((state) => state?.steps.connection.currentStep);
  const profileState = useAppSelector((state) => state?.profile);
  const messages = useAppSelector((state) => state?.profileReview.messages);
  const uuid = useAppSelector((state) => state.auth.uuid);

  useEffect(() => {
    if (typeof currentStep === "number" && currentStep !== 1) {
      dispatch(previousStep({ type: "connection" }));
      navigate("/connection/search");
    }
  }, [currentStep, navigate, dispatch]);

  useEffect(() => {
    if (uuid && !messages?.length) {
      dispatch(fetchReviewStartMessage(uuid));
    }
  }, [uuid, messages]);

  return (
    <>
      <Profile show={!!profileState.profile} />
      <Profiles show={!profileState.profile} />
    </>
  );
};

export default Review;
