// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  width: 100%;
}

body {
  margin: 0;
  font-family: "Sofia Pro", -apple-system, Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  max-width: 100%;
}
body #root,
body .app {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.no-scroll {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,SAAA;EACA,kEAAA;EACA,mCAAA;EACA,kCAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;AAEF;AAAE;;EAEE,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;;AAEA;EACE,+EAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":["html {\n  width: 100%;\n}\nbody {\n  margin: 0;\n  font-family: \"Sofia Pro\", -apple-system, Roboto, Arial, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100vh;\n  width: 100%;\n  max-width: 100%;\n\n  #root,\n  .app {\n    height: 100%;\n    width: 100%;\n    max-width: 100%;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.no-scroll {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
