import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ChatBox from "../../../../shared/chat-box";
import ProfileRates from "../../../../shared/profile-rates";
import { addMessage, chatRequest, ChatRequest, ChatResponse, resetMessages } from "../../../../../store/chat";
import { nextStep, setPermitted } from "../../../../../store/steps";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { Service } from "../../../../../models";
import "./rates.scss";

const predefinedAnswers: string[] = [];

const Rates: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showSnackbarMessage } = useSnackbar();
  const chat = useAppSelector((state) => state.chat);
  const uuid = useAppSelector((state) => state.auth.uuid);
  const [message, setMessage] = useState<string>("");
  const [services, setServices] = useState<Array<Service>>([]);

  const handleMessageChange = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (uuid && message.trim() !== "") {
      dispatch(addMessage({ isAnswer: false, text: message }));

      const body: ChatRequest = { question: message, prompt_type: "rates" };
      const response = await dispatch(chatRequest({ uuid, body }));

      if (chatRequest.fulfilled.match(response)) {
        const payload = response.payload as ChatResponse;

        if (payload.answer) {
          dispatch(addMessage({ isAnswer: true, text: payload.answer }));
        }
        if (payload.processed) {
          dispatch(resetMessages());
          dispatch(setPermitted({ step: 3, permitted: true, type: "expert" }));
          dispatch(nextStep({ type: "expert" }));
        }
        if (payload.services && Array.isArray(payload.services)) {
          setServices(payload.services);
        }
      } else if (chatRequest.rejected.match(response)) {
        showSnackbarMessage(`File send message: ${chat.error}`, "error");
      }
      setMessage("");
    }
  }, [uuid, message, dispatch, showSnackbarMessage, chat.error]);

  useEffect(() => {
    if (!chat.messages.length && chat?.response?.answer && chat?.response?.services) {
      setServices(chat.response.services);
      dispatch(addMessage({ isAnswer: true, text: chat.response.answer }));
    }
  }, [dispatch, chat.response]);

  return (
    <Grid container item spacing={2} sx={{ p: 3 }} overflow="auto" height="100%">
      <Grid item xs={6} height="100%">
        <ChatBox
          messages={chat.messages}
          message={message}
          onNewMessageChange={handleMessageChange}
          onSendMessage={handleSendMessage}
          predefinedAnswers={predefinedAnswers}
          loading={chat.status === "loading"}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <ProfileRates services={services} />
      </Grid>
    </Grid>
  );
};

export default Rates;
