import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { chat } from "../api/profile";
import { Message, Profile } from "../models";

export interface ChatResponse {
  answer?: string;
}

interface ProfilesState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  profile: Profile | null;
  messages: Array<Message>;
}

export const chatRequest = createAsyncThunk(
  "chat/profile/request",
  async (
    { uuid, profileId, question }: { uuid: string; profileId?: string; question?: string },
    { rejectWithValue },
  ) => {
    try {
      return await chat(uuid, profileId, question);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const initialState: ProfilesState = {
  status: "idle",
  error: null,
  profile: null,
  messages: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<Profile | null>) {
      state.profile = action.payload;
    },
    addMessage(state, action: PayloadAction<Message>) {
      if (state.messages) {
        state.messages.push(action.payload);
      }
    },
    resetMessages(state) {
      state.messages = [];
    },
    resetAll(state) {
      state.status = "idle";
      state.error = null;
      state.profile = null;
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chatRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(chatRequest.fulfilled, (state, action: PayloadAction<ChatResponse>) => {
        state.status = "succeeded";
        state.messages.push({ text: action.payload.answer || "", isAnswer: true });
      })
      .addCase(chatRequest.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload?.error?.message || "Failed to fetch data";
      });
  },
});

export const { resetAll: resetProfiles, resetMessages, addMessage, setProfile } = profileSlice.actions;
export default profileSlice.reducer;
