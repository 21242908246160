import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { nextStep, setConnectionReviewView } from "../../../store/steps";
import { Box, Container, Grid, Paper, useTheme } from "@mui/material";
import Stepper from "../../shared/stepper";
import { setProfile } from "../../../store/profile";
import "./connection-steps.scss";

const getPathStepIndex = (path: string) => {
  switch (path) {
    case "/connection/search":
      return 0;
    case "/connection/review":
      return 1;
    case "/connection/account":
      return 2;
    case "/connection/book":
      return 3;
    default:
      return -1;
  }
};

const buttonsConfig = {
  back: [false, true, true, false],
  next: [false, false, false, false],
  confirm: [false, true, true, true],
  share: [false, false, false, false],
};

const ConnectionSteps: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentStep, steps, permitted } = useAppSelector((state) => state.steps.connection);
  const location = useLocation();
  const currentPath = location.pathname;
  const currentView = useAppSelector((state) => state.steps.connection.view.review);
  const stepIndex = getPathStepIndex(currentPath);

  const confirmStep = () => {
    dispatch(nextStep({ type: "connection" }));
    const nextPath =
      stepIndex + 1 < steps.length
        ? `/connection/${steps[stepIndex + 1].toLowerCase()}`
        : "/connection/congratulations";
    navigate(nextPath);
  };

  const handleConfirm = async () => {
    if (stepIndex === 2) {
      switch (currentView) {
        case "profiles":
          dispatch(setConnectionReviewView("profile"));
          break;
        case "profile":
          dispatch(setConnectionReviewView(null));
          confirmStep();
          break;
        default:
          break;
      }
    } else {
      confirmStep();
    }
  };

  const handleShare = async () => {
    dispatch(nextStep({ type: "expert" }));
    const nextPath =
      stepIndex + 1 < steps.length ? `/expert/${steps[stepIndex + 1].toLowerCase()}` : "/connection/search";
    navigate(nextPath);
  };

  const handleNext = () => {
    const nextPath =
      stepIndex + 1 < steps.length ? `/connection/${steps[stepIndex + 1].toLowerCase()}` : "/connection/search";
    navigate(nextPath);
    dispatch(nextStep({ type: "connection" }));
  };

  const handleBack = () => {
    console.log(currentView);
    switch (currentView) {
      case "profiles":
        break;
      case "profile":
        dispatch(setConnectionReviewView("profiles"));
        dispatch(setProfile(null));
        break;
      default:
        break;
    }
  };

  return (
    <Container sx={{ minHeight: "100%" }} maxWidth="xl">
      <Paper
        elevation={3}
        sx={{
          p: 2,
          height: "calc(100% - 50px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          maxHeight: "100vh",
        }}
      >
        <Stepper
          activeStep={currentStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          handleConfirm={handleConfirm}
          handleShare={handleShare}
          content={<Outlet />}
          permitted={permitted}
          loading={false}
          buttonsConfig={buttonsConfig}
          currentView={currentView}
          name="connection"
        />
      </Paper>
    </Container>
  );
};

export default ConnectionSteps;
