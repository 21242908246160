import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { TextFieldVariants } from "@mui/material/TextField/TextField";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import "./input.scss";

interface InputProps {
  type?: string;
  label?: string;
  margin?: "normal" | "dense" | "none";
  placeholder?: string;
  sx?: SxProps<Theme>;
  inputProps?: Partial<OutlinedInputProps>;
  value?: string;
  variant?: TextFieldVariants;
  rightIcon?: React.ReactNode;
  fullWidth?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    value,
    type = "text",
    margin = "normal",
    label = "",
    placeholder = "",
    variant = "outlined",
    rightIcon,
    fullWidth = false,
    sx = null,
    onChange,
    onKeyPress,
    inputProps = {},
  } = props;

  return (
    <TextField
      fullWidth={fullWidth}
      label={label}
      margin={margin}
      type={type}
      placeholder={placeholder}
      variant={variant}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      sx={sx}
      InputProps={{
        endAdornment: rightIcon ? <InputAdornment position="end">{rightIcon}</InputAdornment> : null,
        ...inputProps,
      }}
    />
  );
};

export default Input;
