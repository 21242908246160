import apiClient from "../api/api-client";
import { ChatRequest, ChatResponse } from "../store/chat";

export const chat = async (uuid: string, body: ChatRequest, type: "cv" | "llm" = "cv"): Promise<ChatResponse> => {
  const options: RequestInit = {
    method: "POST",
    body: JSON.stringify(body),
  };

  return apiClient(`/v1/${type}/${uuid}/chat`, options);
};
