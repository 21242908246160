import React from "react";
import { Box, Grid, Typography, IconButton, useTheme, Divider } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.common.black,
        color: theme.palette.text.secondary,
        padding: "10px 0",
        marginTop: 3,
        borderTop: `3px solid ${theme.palette.primary.main}`,
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Leveren
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
        <Grid
          item
          container
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
          sx={{ position: "relative", left: "171px" }}
        >
          <Typography variant="body2" sx={{ textAlign: "center", margin: "0 10px", cursor: "pointer" }}>
            ABOUT
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 2,
              backgroundColor: theme.palette.primary.main,
              width: "2px",
            }}
          />
          <Typography variant="body2" sx={{ textAlign: "center", margin: "0 10px", cursor: "pointer" }}>
            SUPPORT
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 2,
              backgroundColor: theme.palette.primary.main,
              width: "2px",
            }}
          />
          <Typography variant="body2" sx={{ textAlign: "center", margin: "0 10px", cursor: "pointer" }}>
            FOLLOW
          </Typography>
        </Grid>

        <Grid item container xs={6} alignItems="center" sx={{ position: "relative", left: "171px" }}>
          <IconButton aria-label="LinkedIn" sx={{ color: theme.palette.secondary.main, ml: 2 }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton aria-label="Twitter" sx={{ color: theme.palette.secondary.main, ml: 1 }}>
            <TwitterIcon />
          </IconButton>
          <IconButton aria-label="Facebook" sx={{ color: theme.palette.secondary.main, ml: 1 }}>
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="Instagram" sx={{ color: theme.palette.secondary.main, ml: 1 }}>
            <InstagramIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
