import apiClient from "./api-client";
import { ShareResponse } from "../models";

export const summary = async (uuid: string): Promise<ShareResponse> => {
  const options: RequestInit = {
    method: "GET",
  };

  return apiClient(`/v1/cv/${uuid}/summary`, options);
};
