import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {TextField, Button, Typography, Container, Box, useTheme} from "@mui/material";
import { AppDispatch, RootState } from "../../../store";
import { login } from "../../../store/auth";
import Input from "../../shared/input";

const Login: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: RootState) => state.auth.error);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await dispatch(login({ username, password }));
    if (login.fulfilled.match(result)) {
      navigate("/");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ height: theme.custom.layout.bodyHeight }}>
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" justifyContent="center">
        <Typography variant="h4">Login</Typography>
        <Box component="form" onSubmit={handleLogin} mt={2} width="100%">
          <Input label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth={true} />
          <Input
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth={true}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
